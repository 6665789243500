import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, Container, Divider, Header, Segment } from "semantic-ui-react";
import CompletedItem from "../components/CompletedItem";
import IncompleteItem from "../components/IncompleteItem";
import { getAll, getAllMembersForGroup } from "../features/members/memberSlice";
import { getAllTests } from "../features/tests/testSlice";
import { getAllTraitsFromGroup, getSelfTraits, } from "../features/traits/traitSlice";

const Dashboard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { membersArr, allMembers, isLoading } = useSelector((state) => state.members);
    const { testsArr } = useSelector((state) => state.tests);

    const [completed, setCompleted] = useState([]);
    const [incompleted, setIncompleted] = useState([]);
    const [facilitatorCompleted, setFacilitatorCompleted] = useState([]);
    const [facilitatorIncompleted, setFacilitatorIncompleted] = useState([]);
    const groupid = user?.groupid;

    useEffect(() => {
        if (user.role === "admin") {
            return navigate("/group-select");
        }
    }, [navigate, user]);

    useEffect(() => {
        dispatch(getAll());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllTests());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllMembersForGroup());
    }, [dispatch]);
    
    useEffect(() => {
        dispatch(getAllTraitsFromGroup());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getSelfTraits());
    }, [dispatch]);

    const categorizeTestCompletion = () => {
        const facilitators = allMembers.filter(member => membersArr[0] && membersArr[0].facilitator?.split(", ").includes(member.id));
        const allParticipants = [...membersArr, ...facilitators].filter((member) => member.withdrawal !== 1); // filter out withdrawn members so they don't show up in the dashboard

        const newCompleted = [];
        const newIncompleted = [];
        const newFacilitatorCompleted = [];
        const newFacilitatorIncompleted = [];
      
        allParticipants.forEach((participant) => {
            let isCompleted = false;
            testsArr.forEach((test) => {
              if (test.examineeid === participant.id) {
                if (facilitators.includes(participant)) {
                  newFacilitatorCompleted.push({ member: participant, test: test });
                } else {
                  newCompleted.push({ member: participant, test: test });
                }
                isCompleted = true;
              }
            });
            if (!isCompleted) {
              if (facilitators.includes(participant)) {
                newFacilitatorIncompleted.push(participant);
              } else {
                newIncompleted.push(participant);
              }
            }
        });
      
        setCompleted(newCompleted);
        setIncompleted(newIncompleted);
        setFacilitatorCompleted(newFacilitatorCompleted);
        setFacilitatorIncompleted(newFacilitatorIncompleted);
    };

    useEffect(() => {
        categorizeTestCompletion();
    }, [membersArr, testsArr, allMembers]);
    
    const onClick = (e) => {

        e.preventDefault();
        if (incompleted.length > 0) {
        toast.error("Please complete all tests");
        return;
        }
        navigate("/johari-window");
    };

    return (
        
        <Container>
            <Header as="h1" textAlign="center" style={{ marginTop: "3em" }}> Welcome to the Peer Assessment Panel</Header>
            <Segment loading={isLoading} >
                {
                    membersArr.length > 0 ? (
                        <Segment basic>
                            <Header as="h2" textAlign="center">Members
                                <Header.Subheader> Please complete all tests before proceeding</Header.Subheader>
                            </Header>
                            <Card.Group centered stackable itemsPerRow={3}>
                                
                                {incompleted.length > 0 &&
                                    incompleted.map((member, index) => (
                                        <IncompleteItem key={index} member={member} groupid={groupid} />
                                    ))}
                                    {completed.map(({ member, test }, index) => (
                                    <CompletedItem key={index} member={member} test={test} groupid={groupid} />
                                    ))
                                }
                            </Card.Group>
                            {
                                (facilitatorCompleted.length > 0 || facilitatorIncompleted.length > 0) && (
                                <Divider horizontal section > <Header as="h3" textAlign="center">(Optional) Facilitators</Header></Divider>
                                )
                            }
                            <Card.Group centered stackable itemsPerRow={3}>
                            {membersArr.length > 0 ? (
                                <>
                                {facilitatorIncompleted.length > 0 && facilitatorIncompleted.map((member, index) => (
                                    <IncompleteItem key={index} member={member} groupid={groupid} />
                                ))}
                                {facilitatorCompleted.map(({ member, test }, index) => (
                                    <CompletedItem key={index} member={member} test={test} groupid={groupid} />
                                ))}
                                </>
                            ) : (
                                <Header as="h3" textAlign="center">No members to show</Header>
                            )}
                            </Card.Group>
                        </Segment>
                    ) : (
                        <Header as="h3" textAlign="center">No members to show</Header>
                    )
                }
                <Divider section />
                <Container textAlign="center">
                    <Button color="blue" size="huge" fluid onClick={onClick} disabled={incompleted.length > 0}>Results</Button>
                </Container>
            </Segment>
        </Container>
    );
};
export default Dashboard;
